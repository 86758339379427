import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "html",
    component: () => import("../components/Html-w.vue"),
    children: [
      // 聊天机器人
      {
        path: "homtChat",
        component: () => import("../components/homtChat.vue"),
      },
      //历史记录
      {
        path: "HistoricalRecord",
        component: () => import("../components/HistoricalRecord.vue"),
      },
      //首页
      {
        path: "homeTools",
        component: () => import("../components/homtTools.vue"),
      },
      //个人页面
      {
        path: "user",
        component: () => import("../components/user_1.vue"),
      },
      //ai
      {
        path: "Patrick",
        component: () => import("../components/A_1.vue"),
      },
      //配音
      {
        path: "OralDubbing",
        component: () => import("../components/OralDubbing/index.vue"),
      },
      //批改作业
      {
        path: "homework",
        component: () => import("../components/correcting/homework.vue"),
      },
      //魔法配图
      {
        path: "Magic",
        component: () => import("../components/picture/Magic.vue"),
      },
      //魔法图片主页
      {
        path: "gszy",
        component: () => import("../components/gszy_1.vue"),
      },
      //卡通合影
      {
        path: "fwtzz",
        component: () => import("../components/fwtzz/Magic.vue"),
      },
      //魔法帖纸
      {
        path: "Stickers",
        component: () => import("../components/Stickers/Magic.vue"),
      },
      //发音评估
      {
        path: "Speech",
        component: () => import("@/components/Speech/Speech0ne.vue"),
      },
      //发音分类评估
      {
        path: "Pronunciation",
        component: () => import("@/components/Speech/index.vue"),
      },
      //支付
      {
        path: "Payment",
        component: () => import("../components/Payment.vue"),
      },
      //订单
      {
        path: "Subscription",
        component: () => import("../components/Sub/Subscription.vue"),
      },
      //订单详情
      {
        path: "details",
        component: () => import("../components/Sub/details.vue"),
      },
      //订单结果
      {
        path: "Result",
        component: () => import("../components/Sub/Result.vue"),
      },
      // //分享中心
      // {
      //   path:'sharing',
      //   component:()=>import('../components/Link/sharing.vue')
      // },
      //分享链接
      {
        path: "Sharelink",
        component: () => import("../components/Link/index.vue"),
      },
      //订单列表
      {
        path: "OrderList",
        component: () => import("../components/Sub/OrderList.vue"),
      },
      //词汇表
      {
        path: "WordList",
        component: () => import("../components/WordList/Text.vue"),
      },
      //词汇表
      {
        path: "ExportingWords",
        component: () => import("../components/WordList/index.vue"),
      },
      //文本词汇表
      {
        path: "Text",
        component: () => import("../components/WordList/Text.vue"),
      },
      //短语配音
      {
        path: "dubbing",
        component: () =>
          import("../components/OralDubbing/dubbing/dubbing.vue"),
      },

      //单词配音
      {
        path: "OralDubbingdd",
        component: () => import("../components/OralDubbing/OralDubbing.vue"),
      },
      //自定义
      {
        path: "custom",
        component: () => import("../components/OralDubbing/custom.vue"),
      },
      //聊天机器人
      {
        path: "chat",
        component: () => import("@/components/chat/index.vue"),
      },
      //娟妮老师
      {
        path: "chatjuanni",
        component: () => import("@/components/chat/chatjuanni.vue"),
      },
      //资源导航
      {
        path: "Navigation",
        component: () => import("@/components/zy/index.vue"),
      },
      //文件上传
      {
        path: "UploadFiles",
        component: () => import("@/components/UploadFiles/Uploadfiles.vue"),
      },
      //作业批改主页
      {
        path: "petcorrectin",
        component: () => import("@/components/petcorrectin/home.vue"),
      },
      //pet作文批改electronic
      {
        path: "pettask",
        component: () => import("@/components/petcorrectin/homework.vue"),
      },
      //电子文档归纳总结
      {
        path: "electronic",
        component: () => import("@/components/electronic/Uploadfiles.vue"),
      },
      //电子文档归纳总结
      {
        path: "Wordrecitation",
        component: () => import("@/components/Wordrecitation/index.vue"),
      },
      //ppt生成器
      {
        path: "pptcs",
        component: () => import("@/components/pptcs/index.vue"),
      },
      //教程
      {
        path: "video",
        component: () => import("@/components/video/index.vue"),
      },
      //教程
      {
        path: "linking",
        component: () => import("@/components/OralDubbing/linking/index.vue"),
      },
      //教程
      {
        path: "musicians",
        component: () => import("@/components/musicians/musicians.vue"),
      },

      //教程
      {
        path: "lasiodn",
        component: () => import("@/components/OralDubbing/lasiodn/index.vue"),
      },

      //教程
      {
        path: "thkak",
        component: () => import("@/components/thkak/index.vue"),
      },

      //教程
      {
        path: "flashcard",
        component: () => import("@/components/flashcard/index.vue"),
      },

      //教程
      {
        path: "PictureBooks",
        component: () => import("@/components/PictureBooks/PictureBooks.vue"),
      },
      //教程
      {
        path: "language",
        component: () => import("@/components/language/index.vue"),
      },

      //教程
      {
        path: "languageddd",
        component: () => import("@/components/language/language.vue"),
      },

      //教程
      {
        path: "Management",
        component: () => import("@/components/Management/index.vue"),
      },
      //教程
      {
        path: "aisijib",
        component: () => import("@/components/aisijib/index.vue"),
      },
      //教程
      {
        path: "Homeworkpaper",
        component: () => import("@/components/Homeworkpaper/index.vue"),
      },
      {
        path: "WeChat",
        component: () => import("@/components/WeChat/index.vue"),
      },
      // {
   
      //   path: "wechatwechat",
      //   component: () => import("@/components/WeChat/wechat.vue"),
      // },
      {
   
        path: "wechatwechat",
        component: () => import("@/components/videos/index.vue"),
      },
      {
   
        path: "videos",
        component: () => import("@/components/videos/videos.vue"),
      },
      {
   
        path: "fcecorrectin",
        component: () => import("@/components/fcecorrectin/homework.vue"),
      },
      {
   
        path: "Sdc",
        component: () => import("@/components/Sdc/Magic.vue"),
      },
      {
   
        path: "download",
        component: () => import("@/components/xz/index.vue"),
      },
      {
        path: "/work_2",
        name: "work_2",
        component: () => import("@/components/work_2.vue"),
      },
      {
        path: "/WordCards",
        name: "WordCards",
        component: () => import("@/components/WordCards/index.vue"),
      },
      {
        path: "/Findwords",
        name: "Findwords",
        component: () => import("@/components/Findwords/index.vue"),
      },
    
      {
        path: "/cutee",
        name: "cutee",
        component: () => import("@/components/cutee/Magic.vue"),
      },
    
    ],
  },
    {
        path: "/FindwordsFindwords",
        name: "FindwordsFindwords",
        component: () => import("@/components/Findwords/Findwords.vue"),
      },
  // 聊天机器人
  {
    path: "/homtChat",
    name: "homtChat",
    component: () => import("../components/homtChat.vue"),
  },
  //历史记录
  {
    path: "/HistoricalRecord",
    name: "HistoricalRecord",
    component: () => import("../components/HistoricalRecord.vue"),
  },
  //首页
  {
    path: "/homeTools",
    name: "homeTools",
    component: () => import("../components/homtTools.vue"),
  },
  //个人页面
  {
    path: "/user",
    name: "user",
    component: () => import("../components/user_1.vue"),
  },
  //ai
  {
    path: "/Patrick",
    name: "Patrick",
    component: () => import("../components/A_1.vue"),
  },
  // //配音
  // {
  //   path:'/OralDubbing',
  //   name:'OralDubbing',
  //   component:()=>import('../components/OralDubbing/OralDubbing.vue')
  // },
  //批改作业
  {
    path: "/homework",
    name: "homework",
    component: () => import("../components/correcting/homework.vue"),
  },
  //魔法配图
  {
    path: "/Magic",
    name: "Magic",
    component: () => import("../components/picture/Magic.vue"),
  },
  //魔法图片主页
  {
    path: "/gszy",
    name: "gszy",
    component: () => import("../components/gszy_1.vue"),
  },
  //卡通合影
  {
    path: "/fwtzz",
    name: "/fwtzz",
    component: () => import("../components/fwtzz/Magic.vue"),
  },
  //魔法帖纸
  {
    path: "/Stickers",
    name: "Stickers",
    component: () => import("../components/Stickers/Magic.vue"),
  },
  //语音识别
  {
    path: "/Speech",
    name: "Speech",
    component: () => import("@/components/Speech/Speech0ne.vue"),
  },
  //支付
  {
    path: "/Payment",
    name: "Payment",
    component: () => import("../components/Payment.vue"),
  },
  //订单
  {
    path: "/Subscription",
    name: "Subscription",
    component: () => import("../components/Sub/Subscription.vue"),
  },
  //订单详情
  {
    path: "/details",
    name: "details",
    component: () => import("../components/Sub/details.vue"),
  },
  //订单结果
  {
    path: "/Result",
    name: "Result",
    component: () => import("../components/Sub/Result.vue"),
  },
  // //分享中心
  // {
  //   path:'/sharing',
  //   name:'sharing',
  //   component:()=>import('../components/Link/sharing.vue')
  // },
  //分享链接
  {
    path: "/Sharelink",
    name: "Sharelink",
    component: () => import("../components/Link/index.vue"),
  },
  // //分享报告
  // {
  //   path:'/report',
  //   name:'report',
  //   component:()=>import('../components/Link/report.vue')
  // },
  //订单列表
  {
    path: "/OrderList",
    name: "OrderList",
    component: () => import("../components/Sub/OrderList.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/homeTools",
    name: "homeTools",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/homtTools.vue"),
  },

  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/logo/logo.vue"),
  },

  {
    path: "/logo",
    name: "logo_1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/logo_1.vue"),
  },
  {
    path: "/wxlogin",
    name: "wxlogin_1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/wxlogin_1.vue"),
  },
  {
    path: "/ff",
    name: "ff",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/aI/ff.vue"),
  },
  {
    path: "/gpt",
    name: "gpt",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/gpt_t.vue"),
  },
  {
    path: "/work",
    name: "work",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/work_1.vue"),
  },
  {
    path: "/sc",
    name: "sc",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/aI/sc_1.vue"),
  },
  {
    path: "/HomeView",
    name: "HomeView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/aI/HomeView.vue"),
  },
  //配音
  {
    path: "/OralDubbing",
    name: "OralDubbing",
    component: () => import("../components/OralDubbing/index.vue"),
  },
  //批改作业
  {
    path: "/homework",
    name: "homework",
    component: () => import("../components/correcting/homework.vue"),
  },
  //魔法配图
  {
    path: "/Magic",
    name: "Magic",
    component: () => import("../components/picture/Magic.vue"),
  },
  //魔法图片主页
  {
    path: "/gszy",
    name: "gszy",
    component: () => import("../components/gszy_1.vue"),
  },
  //卡通合影
  {
    path: "/fwtzz",
    name: "fwtzz",
    component: () => import("../components/fwtzz/Magic.vue"),
  },
  //魔法帖纸
  {
    path: "/Stickers",
    name: "Stickers",
    component: () => import("../components/Stickers/Magic.vue"),
  },
  //词汇表
  {
    path: "/WordList",
    name: "WordList",
    component: () => import("../components/WordList/Text.vue"),
  },
  //文本词汇表
  {
    path: "/Text",
    name: "Text",
    component: () => import("../components/WordList/Text.vue"),
  },
  //短语配音
  {
    path: "/dubbing",
    name: "dubbing",
    component: () => import("../components/OralDubbing/dubbing/dubbing.vue"),
  },
  //单词配音
  {
    path: "/OralDubbingdd",
    name: "OralDubbingdd",
    component: () => import("../components/OralDubbing/OralDubbing.vue"),
  },
  //自定义
  {
    path: "/custom",
    name: "custom",
    component: () => import("../components/OralDubbing/custom.vue"),
  },
  //发音分类评估
  {
    path: "/Pronunciation",
    name: "Pronunciation",
    component: () => import("@/components/Speech/index.vue"),
  },
  //聊天机器人
  {
    path: "/chat",
    name: "chat",
    component: () => import("@/components/chat/index.vue"),
  },
  //手机zf
  {
    path: "/Mobile",
    name: "Mobile",
    component: () => import("@/components/sjzf.vue"),
  },
  //svg
  {
    path: "/svg",
    name: "svg",
    component: () => import("@/components/svg.vue"),
  },
  //svg
  {
    path: "/svgcopy",
    name: "svgcopy",
    component: () => import("@/components/svgcopy.vue"),
  },
  //资源导航
  {
    path: "/Navigation",
    name: "Navigation",
    component: () => import("@/components/zy/index.vue"),
  },
  //文件上传
  {
    path: "/UploadFiles",
    name: "UploadFiles",
    component: () => import("@/components/UploadFiles/Uploadfiles.vue"),
  },
  //作业批改主页
  {
    path: "/petcorrectin",
    name: "petcorrectin",
    component: () => import("@/components/petcorrectin/home.vue"),
  },
  //pet作文批改
  {
    path: "/pettask",
    name: "pettask",
    component: () => import("@/components/petcorrectin/homework.vue"),
  },
  //电子文档归纳总结
  {
    path: "/electronic",
    name: "electronic",
    component: () => import("@/components/electronic/Uploadfiles.vue"),
  },
  //vip后台
  {
    path: "/vvvvvipht",
    name: "vvvvvipht",
    component: () => import("@/components/vvvvvipht/index.vue"),
  },
  //ppt生成器
  {
    path: "/pptcs",
    name: "pptcs",
    component: () => import("@/components/pptcs/index.vue"),
  },
  //ppt生成器结果
  {
    path: "/pptzjll",
    name: "pptzjll",
    component: () => import("@/components/pptcs/pptzjll.vue"),
  },
  //教程
  {
    path: "/video",
    name: "video",
    component: () => import("@/components/video/index.vue"),
  },

  //教程
  {
    path: "/study",
    name: "study",
    component: () => import("@/components/video/study.vue"),
  },
  //app充值
  {
    path: "/many",
    name: "many",
    component: () => import("@/components/app/many.vue"),
  },
  //教程
  {
    path: "/linking",
    name: "linking",
    component: () => import("@/components/OralDubbing/linking/index.vue"),
  },

  //手机
  {
    path: "/mobilePhone",
    name: "mobilePhone",
    component: () => import("@/components/mobilePhone/index.vue"),
  },

  //sk
  {
    path: "/flashCdard",
    name: "flashCdard",
    component: () => import("@/components/flashcard/flashcard.vue"),
  },
  //教程
  {
    path: "/PictureBooks",
    name: "PictureBooks",
    component: () => import("@/components/PictureBooks/PictureBooks.vue"),
  },

  //教程
  {
    path: "/language",
    name: "language",
    component: () => import("@/components/language/index.vue"),
  },
  //教程
  {
    path: "/languageddd",
    name: "languageddd",
    component: () => import("@/components/language/language.vue"),
  },
  {
    path: "/Management",
    name: "Management",
    component: () => import("@/components/Management/index.vue"),
  },
  {
    path: "/Homeworkee",
    name: "Homeworkee",
    component: () => import("@/components/Homeworkpaper/Homework.vue"),
  },
  //手机首页
  {
    path: "/mobilePhonehome",
    name: "mobilePhonehome",
    component: () => import("@/components/mobilePhone/home/index.vue"),
    children: [
      // 手机首页
      {
        path: "homedd",
        name: "homedd",
        component: () => import("@/components/mobilePhone/home/home.vue"),
      },

      {
        path: "moeny",
        name: "moeny",
        component: () => import("@/components/mobilePhone/moeny/index.vue"),
      },
      {
        path: "my",
        name: "my",
        component: () => import("@/components/mobilePhone/my/index.vue"),
      },
      {
        path: "moeny1",
        name: "moeny1",
        component: () => import("@/components/mobilePhone/moeny/dsaadd.vue"),
      },
    ],
  },
  {
    path: "/work1",
    name: "work1",
    component: () =>
      import("@/components/mobilePhone/home/work/work1/homework.vue"),
  },
  {
    path: "/work3",
    name: "work3",
    component: () =>
      import("@/components/mobilePhone/home/work/workket/homework.vue"),
  },
  {
    path: "/work4",
    name: "work4",
    component: () =>
      import("@/components/mobilePhone/home/fcehome/index.vue"),
  },
  {
    path: "/lsjl",
    name: "lsjl",
    component: () => import("@/components/mobilePhone/home/lsjl.vue"),
  },
  {
    path: "/work1index",
    name: "work1index",
    component: () =>
      import("@/components/mobilePhone/home/work/work1/index.vue"),
  },
  {
    path: "/work2",
    name: "work2",
    component: () =>
      import("@/components/mobilePhone/home/work/work2/homework.vue"),
  },

  {
    path: "/aadds",
    name: "aadds",
    component: () => import("@/components/mobilePhone/home/chat/index.vue"),
  },
  {
    path: "/wordcatesd",
    name: "wordcatesd",
    component: () => import("@/components/WordCards/wordcates.vue"),
  },
  
 
  {
    path: "/rouet--v",
    name: "rouet--v",
    component: () => import("@/components/vvvvvipht/rouet/index.vue"),
    children: [
      // 聊天机器人
      {
        path: "dc",
        component: () => import("@/components/vvvvvipht/rouet/dc.vue"),
      },
      {
        path: "sk",
        component: () => import("@/components/vvvvvipht/rouet/sk.vue"),
      },
      {
        path: "DateComponent",
        component: () => import("@/components/vvvvvipht/rouet/DateComponent.vue"),
      },
    ]
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
